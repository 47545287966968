import React from 'react';

export default ({width, height,classNames})=>{

    return(
        <iframe 
            title='maps'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497.9436110032483!2d101.64265091270144!3d3.2124181057005288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zM8KwMTInNDQuNyJOIDEwMcKwMzgnMzQuNiJF!5e0!3m2!1sen!2sus!4v1615176441961!5m2!1sen!2sus"
            width={width}
            height={height} 
            frameBorder="0" 
            style={{"border":0, width:'100%'}} 
            allowFullScreen={false}
            aria-hidden={false}
            className={classNames}/>
    )
}
