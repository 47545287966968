import React, {useState} from 'react';
import {navigate, Link} from 'gatsby';
import { Squeeze as Hamburger } from 'hamburger-react'
import {Collapse} from 'react-collapse'
import GPRLogo from '../../static/gprLogo.svg'


export default (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () =>{
    setMenuOpen(!menuOpen);
  }
  return (
    <div>
    <div className='max-w-screen flex flex-row flex-nowrap h-16 bg-white py-1.5 px-1 md:px-5 shadow items-center justify-between'>
      
      <div className='flex flex-row items-center cursor-pointer' onClick={()=>navigate('/')}>
      <GPRLogo
        width='80px'
        height='100%'
        className='max-h-14 px-3'/>
        <div className='hidden md:block'>
        {
         props.bigText ?
          <div className='flex flex-row flex-nowrap text-yellow-dark font-bold font-mont items-end '>
            <p className='text-2xl'>
              GREAT PINE RESOURCES 
            </p>
            <p className='pl-2 pb-0.5'>SDN BHD</p>
          </div>
          :
          <div className='flex flex-col'>
            <p className='text-xl text-yellow-dark font-bold font-mont mt-1'>
              GREAT PINE RESOURCES SDN BHD
            </p>
            <p className='bottom-3'>Insurance Agency</p>
          </div>
        }
        </div>
        
        <div className='block md:hidden text-lg font-bold font-mont text-yellow-dark'>
          GREAT PINE RESOURCES
        </div>
      </div>
      
      <div className='hidden lg:block'>
        <Link to='/products' className='mx-5'>
          Our Products
        </Link>
        <Link to='/about'  className='mx-5'>
          About Us
        </Link>
        <Link to='/contact'  className='mx-5 bg-yellow-base text-white px-4 py-3 rounded-lg hover:bg-yellow-dark'>
          Contact Us
        </Link>
      </div>
      <div className ='block lg:hidden'>
        <Hamburger
          size={20}
          color=' #b3b6b7'
          toggled={menuOpen}
          toggle={toggleMenuOpen}/>
      </div>
    </div>
      <Collapse isOpened={menuOpen} >
        <div className='bg-gray-100 flex flex-col items-center text-center shadow-sm pb-3 lg:hidden'>
          <Link to='/products' className='m-3 font-medium'>
            Our Products
          </Link>
          <Link to='/about'  className='m-3 font-medium'>
            About Us
          </Link>
          <Link to='/contact'  className='m-2 font-medium px-4 py-3 bg-yellow-base w-auto text-white  rounded-lg hover:bg-yellow-dark'>
            Contact Us
          </Link>
        </div>
      </Collapse>
    </div>
  )
}