import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneSquareAlt, faEnvelopeSquare} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faDribbbleSquare} from '@fortawesome/free-brands-svg-icons';
import MapsEmbed from './MapsEmbed';
export default () => {

    return (
        <div className='z-50 w-full flex flex-col mb-0 h-full px-4 md:px-10 lg:px-18 py-5' style={{backgroundColor:'#F5F5F5'}}>
            <div className='flex flex-col lg:flex-row h-full lg:justify-between'>
                <div className='flex flex-col flex-nowrap font-medium text-lg mb-10'>
                    <p className='font-bold text-yellow-dark'>Contact Us:</p>
                    <p className='font-bold mt-1.5'>Great Pine Resources Sdn. Bhd.</p>
                    <div> 
                        <FontAwesomeIcon icon={faPhoneSquareAlt} className='mx-3'/>
                        <a href='tel:+60362428264' className='text-2xl'>(+60) 3 6242 8264</a>
                    </div>
                    <div> 
                        <FontAwesomeIcon icon={faEnvelopeSquare} className='mx-3'/>
                        <a href='mailto:wecare@greatpineresources.com.my'>wecare@greatpineresources.com.my</a>
                    </div>
                    <div> 
                        <FontAwesomeIcon icon={faDribbbleSquare} className='mx-3'/>
                        <a href='https://www.greatpineresources.com.my'>greatpineresources.com.my</a>
                    </div>
                </div>

                <div className='flex flex-col flex-nowrap lg:w-2/5 max-w-md mb-10'>
                    <p className='text-yellow-dark font-bold text-lg'>Locate Us</p>
                    <MapsEmbed width='700' height='200' className='rounded-lg'/>
                    <p className='bg-white p-3 pt-5 rounded-b-lg '>No. 9-1, Pusat Niaga Metro Prima,
                        Jalan Prima 9, Metro Prima,
                        Kepong, 52100, Kuala Lumpur</p>
                </div>
            </div>
            <hr style={{border:'1px solid #DADADA'}} className='m-5'/>
            <div className='w-full text-black text-opacity-50 text-center text-sm'>All Rights Reserved.<br/>Great Pine Resources Sdn. Bhd. © {(new Date()).getFullYear()}</div>


        </div>
    )

}