import React from 'react';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";

export default (props) => {
  return(
    <>
    <Helmet>
      <title>Great Pine Resources</title>
      <html lang="en" />
      <meta charset="utf-8"/>
      <meta name="description" content="Great Pine Resources Insurance Agency" />
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <link rel="icon" href="gprLogo.svg"/>
    </Helmet>
    {props.uri ==='/' ? <Header bigText/> : <Header/>}
    {props.children}
    {props.uri ==='/inquirySubmitted' ? <></> : <Footer/>}
    </>
  )
}